<template>
  <div class="lookparticulars">
    <div class="nav">
      <Carousel show="1" />
    </div>
    <div class="navcon">
      <p class="navconleft">
        <!-- 新闻动态 -->
        <span
          class="NewsInformation"
          :style="{
            background: show == 'NewsInformation' ? '#197CC3' : '#fff',
            color: show == 'NewsInformation' ? '#fff' : '#000',
            zIndex: show == 'NewsInformation' ? '5' : '',
          }"
          @click="gopage('NewsInformation', $event)"
        >
          {{ $t("language.NewInformation") }}
          <b style="font-weight: normal">|</b>
        </span>
        <!-- 精彩瞬间 -->
        <span
          :style="{
            background: show == 'wonderful' ? '#197CC3' : '#fff',
            color: show == 'wonderful' ? '#fff' : '#000',
            zIndex: show == 'wonderful' ? '5' : '',
          }"
          @click="gopage('wonderful', $event)"
        >
          {{ $t("language.WonderfulMoment") }}
          <b style="font-weight: normal">|</b>
        </span>
        <!-- 展会排期 -->
        <span
          :style="{
            background: show == 'ExhibitionScheduling' ? '#197CC3' : '#fff',
            color: show == 'ExhibitionScheduling' ? '#fff' : '#000',
            zIndex: show == 'ExhibitionScheduling' ? '5' : '',
          }"
          @click="gopage('ExhibitionScheduling', $event)"
        >
          {{ $t("language.ExhibitionScheduling") }}
        </span>
        <!-- 通知公告 -->
        <span
          :style="{
            background: show == 'NoticeNotice' ? '#197CC3' : '#fff',
            color: show == 'NoticeNotice' ? '#fff' : '#000',
            zIndex: show == 'NoticeNotice' ? '5' : '',
          }"
          @click="gopage('NoticeNotice', $event)"
        >
          {{ $t("language.NoticeNotice") }}
        </span>
      </p>
      <p class="navconright">
        <van-icon color="#999999" name="wap-home" size="20" />
        <span>
          {{ $t("language.current") }}
          <!-- 当前位置 -->
          :
          {{ $t("language.HOMEpage") }}
          <!-- 首页 -->
          >
          <!-- {{$t('language.ExhibitionTrends')}} -->
          <span class="spans" v-html="$t('language.ExhibitionTrends')"></span>
          <!-- 展会动态 -->
          >
          <span v-if="show == 'NewsInformation'">{{ $t(`language.NewInformation`) }}</span>
          <span v-else-if="show == 'wonderful'">{{ $t(`language.WonderfulMoment`) }}</span>
          <span v-else-if="show == 'ExhibitionScheduling'">{{ $t(`language.ExhibitionScheduling`) }}</span>
          <span v-else-if="show == 'NoticeNotice'">{{ $t(`language.NoticeNotice`) }}</span>
          
        </span>
      </p>
    </div>
    <div class="content">
      <p class="contenttitle">{{ lookparticulars.title }}</p>
      <p class="releaseTime">
        <span>作者：{{lookparticulars.author}}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span>发布时间：{{ lookparticulars.releaseTime }}</span>
      </p>
      <p class="text" v-html="lookparticulars.newsContent"></p>
    </div>
    <!-- <div class="content" v-if="show == 'NewsInformation'">
      <NewsInformation />
    </div>
    <div class="content" v-else-if="show == 'wonderful'">
      <wonderful />
    </div>
    <div class="content" v-else-if="show == 'ExhibitionScheduling'">
      <ExhibitionScheduling />
    </div> -->
    <div class="bot">
      <Bottom />
    </div>
  </div>
</template>

<script>
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
// import NewsInformation from "../../components/pcTradeShowTrends/NewsInformation.vue";
// import wonderful from "../../components/pcTradeShowTrends/wonderful.vue";
// import ExhibitionScheduling from "../../components/pcTradeShowTrends/ExhibitionScheduling.vue";
export default {
  components: {
    Carousel,
    Bottom,
    // NewsInformation,
    // wonderful,
    // ExhibitionScheduling,
  },
  data() {
    return {
      lookparticulars: "",
      show:null
    };
  },
  created() {
    let data = this.$route.query.id;
    this.$api.lookparticulars(data, (res) => {
      if (res.data.code == 200) {
        console.log(res.data.data);
        this.lookparticulars = res.data.data;
        this.scroll();
      }
    });
    this.showcomponent();
  },
  mounted() {},
  methods: {
    scroll() {
      let traffic = document.getElementsByClassName("lookparticulars")[0];
      this.$nextTick(() => {
        setTimeout(() => {
          let targetbox = document.getElementsByClassName("navcon")[0];
          traffic.scrollTop = targetbox.offsetTop;
        });
      });
    },
    showcomponent() {
      console.log("aaa",this.$route.query.path)
      if (this.$route.query.path == "新闻动态") {
        this.show = "NewsInformation";
      } else if (this.$route.query.path == "精彩瞬间") {
        this.show = "wonderful";
      } else if (this.$route.query.path == "展会排期") {
        this.show = "ExhibitionScheduling";
      } else if (this.$route.query.path == "通知公告") {
        this.show = "NoticeNotice";
      } else if (this.$route.query.path == "/") {
        this.show = "NewsInformation";
      } else if (this.$route.query.path == "") {
        this.show = "NewsInformation";
      }
      console.log("bbb",this.show)
    },
    gopage(val, e) {
      if (val == "NewsInformation") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `新闻动态` },
        });
      } else if (val == "wonderful") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `精彩瞬间` },
        });
      } else if (val == "ExhibitionScheduling") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `展会排期` },
        });
      } else if (val == "NoticeNotice") {
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `通知公告` },
        });
      }
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
    },
  },
};
</script>

<style scoped>
.lookparticulars::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.lookparticulars {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.nav {
  width: 100%;
  height: 100%;
}
/* .NewsInformation {
  background-color: #1a7cc3 !important;
  color: #fff !important;
} */
.navcon {
  width: 1400px;
  margin: 0 auto;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}
.navconleft span {
  display: inline-block;
  line-height: 70px;
  text-align: center;
  padding: 0px 20px;
}
.navconleft :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.navconleft :hover b {
  display: none !important;
}
.navconleft span {
  position: relative;
}
.navconleft b {
  position: absolute;
  z-index: 2 !important;
  right: -3px;
}
.navconright {
  line-height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navconright > span {
  display: inline-block;
}
.content {
  box-sizing: border-box;
  width: 1400px;
  margin: 0 auto;
  /* text-align: center; */
  background-color: #fafafa;
  margin-top: 10px;
  padding: 20px 95px 20px 95px;
  margin-bottom: 30px;
}
.contenttitle {
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  line-height: 100px;
}
.releaseTime {
  font-size: 16px;
  color: #666;
  line-height: 50px;
  text-align: center;
}
.text {
  font-size: 16px;
  line-height: 40px;
  width: 800px;
  margin: 0 auto;
  text-indent:36px;
}
.text >>>.ql-align-center{
  text-align: center;
  text-indent:0;
}
.text >>> strong{
  display: block;
  /* text-align: center; */
  text-indent:0;
}
.text >>> img {
  width: 500px !important;
  display: block;
  margin: 0 auto;
  text-indent:0;
}
</style>